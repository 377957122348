import axios from "@/http/httpWebsite.js";

export default {

  doctorAnswerQuestionsList(id) {
    const url = `/doctor/${id}/answer-question-list`;
    return axios.get(url);
  },

  doctorPublishedContentList(id){
    const url = `/doctor/${id}/published-content`;
    return axios.get(url);
  },

  getDoctorInfo(username){
    const url = `/doctor/${username}/info`;
    return axios.get(url);
  },
  
  podcastInfo(id) {
    const url = `/podcast/${id}/info`;
    return axios.get(url);
  },

  videoInfo(id){
    const url = `/video/${id}/info`;
    return axios.get(url);
  },
 
  postInfo(id){
    const url = `/post/${id}/info`;
    return axios.get(url);
  },

  questionInfo(id){
    const url = `/question/${id}/detail`;
    return axios.get(url);
  },
  
  podcastCommentList(id) {
    const url = `/podcast/${id}/comment-list`;
    return axios.get(url);
  },

  postCommentList(id) {
    const url = `/post/${id}/comment-list`;
    return axios.get(url);
  },

  videoCommentList(id) {
    const url = `/video/${id}/comment-list`;
    return axios.get(url);
  },

  VideoView(id) {
    const url = `/video/view/${id}`
    return axios.get(url)
  },

  PostView(id) {
    const url = `/post/view/${id}`
    return axios.get(url)
  },

  latestDoctors(){
    const url = `/doctor/latest-signup`
    return axios.get(url)
  },

  doctoruser() {
    const url = `/doctor/mindologyuser/info`;
    return axios.get(url);
  }
}