<template>
  <v-app class="bg-light">
    <div class="container" v-show="shows.showNotFound">
      <div class="col-12" :style="{ height: windowWidth }">
        <div class="col-12 text-center" style="margin-top: 15%; height: 120px">
          <p class="h1 text-center" :class="errColor">
            {{ err[erri] }}
          </p>
        </div>
      </div>
    </div>

    <div v-show="shows.loader">
      <div
        class="d-flex justify-content-center mx-auto"
        style="max-width: 800px; height: 700px"
      >
        <div
          class="spinner-border h1 text-primary"
          role="status"
          style="height: 90px; width: 90px; margin-top: 25%"
        ></div>
      </div>
    </div>

    <b-container v-show="shows.showArticle">
      <b-container class="d-flex p-0">
        <div
          class="col-xs-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 mx-auto"
        >
        
            <b-alert show variant="secondary"><b-link to="/download">برای پرسیدن سوال خود از متخصصیص اپلیکشن مایندولوژی را دانلود کنید</b-link></b-alert>
          <b-card no-body>
            <div class="col-12 border rounded d-flex flex-column h-100">
              <div
                class="d-flex flex-row justify-content-between mb-2 align-items-center"
              >
                <span
                  class="small font-weight-bold light-blue lighten-5 light-blue--text text--darken-3 rounded px-2 py-1"
                >
                  سوال
                </span>
                <span
                  class="small font-weight-light text-black-50 rounded p-0 mt-2"
                >
                  {{ DateCalculate(questionHolder.createDate) }}
                </span>
              </div>

              <div class="py-2 small font-weight-bold">
                <p class="text-right my-0" v-html="questionHolder.question">
                </p>
              </div>

              <b-card no-body v-for="(answer, index) in questionHolder.answers" :key="index" class="my-3">
                <div class="col-12 border rounded d-flex flex-column h-100">
                  <div
                    class="d-flex flex-row justify-content-between mb-2 align-items-center"
                  >
                    <span
                      class="small font-weight-bold light-green lighten-4 light-green--text text--darken-4 rounded px-2 py-1"
                    >
                      پاسخ {{index+1}}
                    </span>
                    <span
                      class="small font-weight-light text-black-50 rounded p-0 mt-2"
                    >
                      {{ DateCalculate(answer.createDate) }}
                    </span>
                  </div>

                  <div class="py-2 small">
                    <p class="my-0" v-html="answer.answer">
                    </p>
                  </div>
                  <div class="mt-2">
                    <div class="d-flex flex-row align-items-center">
                      <section class="d-flex flex-row avatar-group rtl">
                        <span
                          class="avatars-group__item avatar "
                        >
                          <v-avatar size="36" color="indigo" class="bordered">
                            <img
                              :src="answer.doctorProfilePic"
                              :alt="answer.doctorFullName"
                              v-if="answer.doctorProfilePic"
                            />

                            <span
                              v-else
                              class="white--text text-small font-weight-bold"
                            >
                              {{ answer.doctorFullName.slice(0, 1) }}
                            </span>
                          </v-avatar>
                        </span>
                      </section>
                      <div>
                        <span class="text-small font-weight-bold ps-1">
                          {{ answer.doctorFullName }}
                        </span>
                      </div>
                    </div>

                    <div class="text-left mt-1">
                      <div @click="setLikeAnswer(answer)"
                        class="d-flex d-inline-flex align-items-center bg-secondary px-1 rounded text-white cursor"
                      >
                        <i class=" fas fa-heart mx-1 fa-sm"></i>
                        <span class="me-1 text-white text-small">{{
                          answer.userLikeAnswers.length
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </b-card>
        </div>
      </b-container>
    </b-container>
  </v-app>
</template>

<script>
import apiService from "@/http/ApiServiceWebsite.js";
var moment = require("moment");

export default {
  data() {
    return {
      questionHolder: {},

      shows: {
        showArticle: false,
        showNotFound: false,
        loader: true,
      },

      err: [
        " خطا در پردازش ",
        "صفحه مورد نظر یافت نشد !",
        "محتوایی یافت نشد !",
      ],
      erri: 0,
      errColor: "text-danger",
    };
  },
  methods: {
    loadQuestion(id) {
      apiService
        .questionInfo(id)
        .then((response) => {
          if (response.status == 200) {
            this.questionHolder = response.data;
            this.shows.videoPlayerShow = true;
            this.shows.showArticle = true;
            this.shows.loader = false;
            this.shows.showNotFound = false;
          } else {
            this.shows.loader = false;
            this.shows.showArticle = false;
            this.shows.showNotFound = true;
            this.errColor = "text-danger";
            this.erri = 1;
          }
        })
        .catch((error) => {
          this.shows.loader = false;
          this.shows.showNotFound = true;
          this.shows.showArticle = false;

          this.errColor = "text-danger";
          if (error.response.status == 422 || error.response.status == 500) {
            this.erri = 1;
          } else {
            this.erri = 0;
          }
        });
    },
    DateCalculate(then) {
      var now = new Date();
      var diffDays = moment(now).diff(moment(then), "days");
      if (diffDays < 1) {
        return "امروز";
      } 
      else if(diffDays > 1 && diffDays < 7) {
        return diffDays + " روز پیش";
      }
      else if (diffDays >= 7 && diffDays <= 28) {
        return Math.round(diffDays/7) + " هفته پیش"
      }
      else if (diffDays > 28 && diffDays <= 365) {
        return Math.round(diffDays/30) + " ماه پیش"
      }
      else if (diffDays > 365) {
        return Math.round(diffDays/365) + " سال پیش";
      }
      else {
        return diffDays + " روز پیش";
      }
    },
    setLikeAnswer(like) {
      if (!this.isLoggedIn) {
        this.$bvToast.toast(
          `برای دسترسی به این قسمت لطفا اپلیکیشن مایندولوژی را دانلود نمائید`,
          {
            to: '/download',
            title: `دسترسی `,
            toaster: "b-toaster-bottom-left",
            solid: true,
            variant: "danger",
            appendToast: false,
            noCloseButton: true,
          }
        );
      }
    },
  },
  beforeMount() {
    const id = this.$route.params.id;
    this.loadQuestion(id);
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style lang="scss">
.avatars-group__item {
  cursor: default;
  transition: all 0.1s ease-out;
  &.more {
    align-items: center;
    display: flex;
    &:hover {
      transform: none;
    }
  }
  &:hover {
    transform: translateY(-4px);
    z-index: 1;
  }
}

.v-avatar {
  &.bordered {
    box-shadow: 0px 0px 0px 2px #fff inset;
    img {
      padding: 2px;
    }
    &.small {
      box-shadow: 0px 0px 0px 1px #fff inset;
      img {
        padding: 1px;
      }
    }
  }
}

.avatar-group > :not(:first-child) {
  margin-right: -12px !important;
}
</style>
